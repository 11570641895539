<template>
    <div class="comment-container">
        <template v-if="comment.content">
            <div class="comment-leader">
                {{comment.leaderName}}评语
                <span class="comment-time">
                    {{comment.createTime | dateFilter}}
                </span>
            </div>
            <pre class="comment-text">{{comment.content}}</pre>
        </template>
        <template v-else-if="isTeamLeader">
            <el-input
                class="comment-input"
                type="textarea"
                :maxlength="2000"
                :rows="6"
                placeholder="请对TrackingList进行评价并反馈给到成员"
                v-model="commentText"
            ></el-input>
            <div class="comment-button">
                <el-button
                    class="comment-empty"
                    @click="handleEmpty">
                    置空
                </el-button>
                <el-button
                    type="primary"
                    class="comment-confirm"
                    @click="handleConfirm">
                    提交
                </el-button>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        isTeamLeader: Boolean,
        comment: {
            type: Object
        }
    },
    data() {
        return {
            commentText: ""
        }
    },
    filters: {
        dateFilter(val) {
            return moment(val).format('YYYY-MM-DD HH:mm')
        }
    },
    methods: {
        handleEmpty() {
            this.commentText = "";
        },
        handleConfirm() {
            if(this.commentText.trim()) {
                this.$emit('comment-confirm', this.commentText);
            } else {
                shortTips('请输入评语!');
                this.commentText = '';
            }
        }
    }
}
</script>

<style lang="scss" scope>
    .comment-container {
        width: 1130px;
        margin: 30px auto 20px;
        .comment-leader {
            font-size: 14px;
            color: #333;
            line-height: 20px;
            margin-bottom: 10px;
            margin-top: 20px;
            font-weight: bold;
            .comment-time {
                color: #999;
                margin-left: 18px;
                font-weight: normal;
            }
        }
        .comment-text {
            background: #F8F8F8;
            color: #333;
            border-radius: 4px;
            padding: 12px 10px;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 24px;
            white-space: pre-wrap;
            word-wrap: break-word;
        }
        .comment-input textarea{
            width: 100%;
            padding: 12px 10px;
            border: 1px solid #DDDDDD;
            border-radius: 4px;
        }
        .comment-button {
            margin-top: 10px;
            text-align: right;
            .comment-empty {
                margin-right: 10px;
            }
        }
    }
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comment-container" },
    [
      _vm.comment.content
        ? [
            _c("div", { staticClass: "comment-leader" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.comment.leaderName) +
                  "评语\n            "
              ),
              _c("span", { staticClass: "comment-time" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._f("dateFilter")(_vm.comment.createTime)) +
                    "\n            "
                ),
              ]),
            ]),
            _c("pre", { staticClass: "comment-text" }, [
              _vm._v(_vm._s(_vm.comment.content)),
            ]),
          ]
        : _vm.isTeamLeader
        ? [
            _c("el-input", {
              staticClass: "comment-input",
              attrs: {
                type: "textarea",
                maxlength: 2000,
                rows: 6,
                placeholder: "请对TrackingList进行评价并反馈给到成员",
              },
              model: {
                value: _vm.commentText,
                callback: function ($$v) {
                  _vm.commentText = $$v
                },
                expression: "commentText",
              },
            }),
            _c(
              "div",
              { staticClass: "comment-button" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "comment-empty",
                    on: { click: _vm.handleEmpty },
                  },
                  [_vm._v("\n                置空\n            ")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "comment-confirm",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleConfirm },
                  },
                  [_vm._v("\n                提交\n            ")]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div
        class="comment-table-container">
        <el-table
            ref="tlCommentTable"
            class="comment-table"
            border
            :data="tableData"
            empty-text="-"
            tooltip-effect="light">
            <el-table-column
                label="成员姓名"
                prop="userName"
                width="120"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
                label="职位"
                prop="title"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
                label="公司"
                prop="company"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
                label="城市"
                prop="location"
                width="88px"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
                label="学历"
                width="88px">
                <template slot-scope="scope">
                    <span>
                        {{scope.row.degree | degreeFilter}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                label="学校"
                prop="school"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
                label="工作年限"
                prop="yearOfExperience"
                width="98px"
                align="center">
            </el-table-column>
            <el-table-column
                label="候选人姓名"
                prop="resumeRealName"
                width="126px"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.resumeId && scope.row.isFirmResume">
                        <!-- <a :href="`/Headhunting/MyCompany.html#/candidateDetail/${scope.row.resumeId}`" target="_blank">{{scope.row.resumeRealName}}</a> -->
                        <a :href="`/#/candidateDetail/${scope.row.resumeId}`" target="_blank">{{scope.row.resumeRealName}}</a>
                    </span>
                    <span v-else>{{scope.row.resumeRealName}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="加入时间"
                width="108px"
                align="center">
                <template slot-scope="scope">
                    <div>
                        {{scope.row.trackingDate | dateFilter}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="推荐时间"
                width="98px"
                align="center">
                <template slot-scope="scope">
                    <div>
                        {{scope.row.recommendDate | dateFilter}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="寻访记录"
                width="98px">
                <template slot-scope="scope">
                    <el-popover
                        ref="inquiryLogPopover"
                        placement="bottom-start"
                        width="310"
                        popper-class="inquiry-log-popper"
                        trigger="hover"
                        :visible-arrow="false"
                        :disabled="scope.row.inquiryLogsCount === 0"
                        @show="showInquiryLog(scope.row)">
                        <span slot="reference">
                            <font-icon class="table-body-icon-big icon-inquiry" href="#icon-talent_inquiry"></font-icon>
                            <span>&nbsp;{{scope.row.inquiryLogsCount}}</span>
                        </span>
                        <div v-loading="!scope.row.lastestInquiryLog">
                            <template v-if="scope.row.lastestInquiryLog">
                                <div class="inquiry-log-userInfo">
                                    <img :src="scope.row.lastestInquiryLog.creatorAvatar">
                                    <span class="name">{{ scope.row.lastestInquiryLog.creatorName }}</span>
                                    <span class="time">{{ scope.row.lastestInquiryLog.created | timeFilter }}</span>
                                </div>
                                <div class="inquiry-log-content">{{ scope.row.lastestInquiryLog.content }}</div>
                                <record-listen
                                    :controlBtnWidth="10"
                                    :progressWidth="140"
                                    :audio-url="scope.row.lastestInquiryLog.audioRecordUrl"
                                    :duration="getAudioDuration(scope.row.lastestInquiryLog)"
                                    v-if="scope.row.lastestInquiryLog.audioRecordUrl"
                                ></record-listen>
                                <div
                                    class="inquiry-log-detail"
                                    :style="scope.row.lastestInquiryLog.audioRecordUrl ? 'margin-top: 5px;' : ''"
                                    v-if="scope.row.lastestInquiryLog.jobHoppingOccasion || scope.row.lastestInquiryLog.employerInfo || scope.row.lastestInquiryLog.expectSalary">
                                    <div
                                        class="inquiry-log-detail-item"
                                        v-if="scope.row.lastestInquiryLog.jobHoppingOccasion">
                                        跳槽时机：{{scope.row.lastestInquiryLog.jobHoppingOccasion}}
                                    </div>
                                    <div
                                        class="inquiry-log-detail-item"
                                        v-if="scope.row.lastestInquiryLog.employerInfo">
                                        雇主画像：{{scope.row.lastestInquiryLog.employerInfo}}
                                    </div>
                                    <div
                                        class="inquiry-log-detail-item"
                                        v-if="scope.row.lastestInquiryLog.expectSalary">
                                        期望报酬：{{scope.row.lastestInquiryLog.expectSalary}}
                                    </div>
                                </div>
                            </template>
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column
                label="加入渠道"
                min-width="70px"
                :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <div class="source-content">
                        {{scope.row.addTrackingListSourceDetail}}{{scope.row.addTrackingListSourceDetail.indexOf('FloatingList') === -1 ? '手动加入' : '自动加入'}}
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <span class="empty-img"></span>
                <span class="empty-data">暂无数据</span>
            </template>
        </el-table>
        <div class="work-table-footer">
            <el-pagination
                :current-page="page.current"
                :page-sizes="[15, 20, 50]"
                :page-size="page.size"
                class="el-pagination-workTable"
                layout="total, sizes, prev, pager, next, slot"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
                <span class="pagination-text">
                    <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                    <span @click="handlePagerJump">跳转</span>
                </span>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import RecordListen from '#/component/common/record-listen.vue';
import DegreeKeyValue from '#/js/config/degreeKeyValue.json';
import TalentPoolService from '#/js/service/talentPoolService.js';
import moment from 'moment';
import popover from '#/js/mixins/popover.js';
export default {
    props: {
        tableData: {
            type: Array
        }
    },
    mixins: [popover],
    components: {
        RecordListen,
    },
    filters: {
        degreeFilter(val) {
            return DegreeKeyValue[val]
        },
        dateFilter(val) {
            return val ? moment(val).format('YYYY-MM-DD') : '-';
        },
        timeFilter(val) {
            return moment(val).format('YYYY-MM-DD H:mm');
        }
    },
    data() {
        return {
            page: {
                current: 0,
                size: 15,
                total: 0
            },
            pagerJump: 0
        }
    },
    methods: {
        handlePagerJump(){
            let currentPager = Number(this.pagerJump),
            currentPageTotal = Math.ceil(this.page.total/this.page.size);
            if(currentPager > 0 && currentPager <= currentPageTotal) {
                this.pagerJump = 0;
                this.handleCurrentChange(currentPager)
            }
        },
        handleCurrentChange(val){
            this.page.current = val;
            this.$emit('search', {
                start: this.page.size * (this.page.current - 1)
            }, true)
        },
        handleSizeChange(val) {
            this.page.size = val;
            this.page.current = 1;
            this.$emit('search', {
                take: this.page.size,
                start: 0
            }, true)
        },
        getAudioDuration(inquiryLog) {
            return parseInt(inquiryLog.audioDuration) / 60;
        },
        showInquiryLog(resume){
            if(resume.lastestInquiryLog && resume.lastestInquiryLog.resumeId) {
                return false;
            }
            TalentPoolService.getLatestInquiryLogs([resume.resumeId])
                .then(res => {
                    res.map(item => {
                        resume.lastestInquiryLog = item;
                    })
                    this.$nextTick(() => {
                        this.popoverShow(this.$refs.inquiryLogPopover);
                    })
                });
        }
    }
}
</script>

<style lang="scss" scope>
.comment-table-container {
    .comment-table {
        min-height: 300px;
        /deep/ .el-table {
            thead tr th {
                background-color: #F0F4F7;
                .cell {
                    color: #666;
                    font-weight: bold;
                    padding: 0 20px;
                }
            }

            td .cell {
                padding: 0 20px;
            }

            th, td {
                padding: 8px 0;
            }
        }

        .el-table__header th {
            background-color: #F0F4F7;
            color: #666;
        }
        // tr.el-table__row:nth-of-type(2n) {
        //     background-color: #f8f8f8;
        //     td {
        //         color: #666;
        //     }
        // }
        .source-content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .el-table__empty-block {
            margin: 12px 0;
            .el-table__empty-text {
                line-height: inherit;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                background-size: contain;
                width: 200px;
                height: 180px;
            }
            .empty-data {
                color: #444;
                font-size: 16px;
                line-height: 21px;
                margin-top: 12px;
            }
        }
    }
    .work-table-footer {
        margin: 35px 35px 0 0;
    }
}
</style>

<style lang="scss">
    .inquiry-log-popper.el-popper {
        margin-top: 5px;
        min-width: 50px;
        min-height: 50px;
        color: #666;
        .inquiry-log-userInfo{
            margin-bottom: 7px;
            img {
                display: inline-block;
                margin-right: 5px;
                width: 26px;
                height: 26px;
                border-radius: 50%;
                border: 1px solid;
            }
            .name,.time {
                font-size: 12px;
            }

            .time {
                margin-left: 10px;
                color: #999;
            }
        }
        .inquiry-log-content {
            max-height: 95px;
            overflow-y: auto;
        }
        .inquiry-log-detail {
            margin-top: 20px;
            &-item {
                line-height: 22px;
                &::before {
                    content: "·";
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 20px;
                    margin-right: 7px;
                }
            }
        }
        .el-loading-spinner {
            margin-top: -5px;
        }
    }
</style>
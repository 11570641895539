<template>
    <div class="tl-comment-search">
        <el-date-picker
            class="tl-comment-search-item tl-comment-search-date"
            v-model="commentSearchDate"
            type="week"
            :editable="false"
            :clearable="false"
            align="center"
            format="yyyy 第 WW 周"
            value-format="yyyy-MM-dd"
            placeholder="选择周"
            popper-class="tl-comment-data-picker"
            :picker-options="commentPickerOption"
            @change="handleSearchDate"
        ></el-date-picker>
        <el-dropdown
            placement="bottom-start"
            trigger="click"
            v-if="teamId"
            @command="handleSearchMember">
            <span class="tl-comment-search-item tl-comment-search-member">
                {{commentSearchUser.userName}}
            </span>
            <el-dropdown-menu
                slot="dropdown"
                class="tl-comment-member-list">
                <el-dropdown-item
                    v-for="(member, index) in memberList"
                    :key="index"
                    :command="index"
                    :class="commentSearchUser.trackingListSnapshotId === member.trackingListSnapshotId ? 'is-selected' : ''">
                    <i class="el-icon-check selected-icon" v-if="commentSearchUser.trackingListSnapshotId === member.trackingListSnapshotId"></i>
                    <span class="member-name">
                        {{member.userName}}
                    </span>
                    <span class="feedback-status" :class="member.isFeedback ? 'is-feedback' : ''" v-if="member.trackingListSnapshotId !== ''">
                        {{member.isFeedback ? '已反馈' : '未反馈'}}
                    </span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import moment from 'moment';
import TrackingListService from '#/js/service/trackingListService';
export default {
    props: {
        teamId: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            commentSearchDate: "",
            commentSearchUser: {
                trackingListSnapshotId: "",
                userName: "全部成员"
            },
            params: {
                startDate: "",
                endDate: "",
                snapshotIds: []
            },
            commentPickerOption:{
                disabledDate(date) {
                    return date.getTime() >= new Date(new Date().toDateString()).getTime();
                },
                firstDayOfWeek: 1
            },
            memberList: []
        }
    },
    mounted() {},
    methods: {
        getMemberList() {
            this.memberList = [];
            TrackingListService.getMembers({
                teamId: this.teamId,
                startDate: this.params.startDate,
                endDate: this.params.endDate
            }).then(res => {
                this.memberList = res;
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.memberList.unshift({
                    userName: "全部成员",
                    trackingListSnapshotId: ""
                })
                let selectedIndex = this.memberList.findIndex(user => user.trackingListSnapshotId === this.commentSearchUser.trackingListSnapshotId);
                if(selectedIndex < 0) {
                    selectedIndex = 0;
                }
                this.handleSearchMember(selectedIndex);
            })
        },
        handleSearchDate(date) {
            // 个人的trackingList周报时间仅影响列表
            // 团队的则会影响团队列表，因此时间改变时需要重新获取团队列表
            this.commentSearchDate = date;
            this.params.startDate = moment(date).weekday(0).format("YYYY-MM-DD");
            this.params.endDate = moment(this.params.startDate).add(6, 'days').format("YYYY-MM-DD");
            if(this.teamId === "") {
                this.$emit('search', this.params);
            } else {
                this.getMemberList();
            }
        },
        handleSearchMember(index = 0) {
            let selectedMember = this.memberList[index];
            this.commentSearchUser = selectedMember;
            if(selectedMember.trackingListSnapshotId === "") {
                let list = [];
                this.memberList.slice(1).forEach(user => {
                    list.push(user.trackingListSnapshotId);
                })
                this.params.snapshotIds = list;
            } else {
                this.params.snapshotIds = [selectedMember.trackingListSnapshotId];
            }
            this.$emit('search', this.params);
        },
    }
}
</script>

<style lang="scss" scope>
    .tl-comment-search {
        display: flex;
        justify-content: center;
        // margin-bottom: 54px;
        &-item {
            min-width: 132px;
            height: 32px;
            padding-left: 12px;
            padding-right: 42px;
            text-align: center;
            line-height: 30px;
            border: 1px solid #dcdfe6;
            // border-radius: 16px;
            position: relative;
            overflow: hidden;
            &::after {
                content: "";
                position: absolute;
                right: 16px;
                top: 10px;
                width: 0;
                height: 0;
                border-top: 12px solid #ddd;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: none;
            }
            &:hover {
                border-color: $primary;
                background-color: $primary;
                color: #fff;
                &::after {
                    border-top: none;
                    border-bottom: 12px solid #fff;
                }
            }
        }
        &-date {
            margin-right: 10px;
            padding: 0;
            max-width: 150px;
            border: none;
            .el-input__inner {
                padding-left: 12px;
                padding-right: 42px;
                // border-radius: 16px;
                height: 100%;
                line-height: inherit;
                cursor: pointer;
                &:hover {
                    background-color: $primary;
                    color: #fff;
                }
            }
            .el-input__prefix {
                display: none;
            }
        }
        &-member {
            display: inline-block;
            max-width: 224px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
</style>

<style lang="scss">
.tl-comment-data-picker.el-popper {
}
.tl-comment-member-list.el-dropdown-menu {
    margin-top: 0;
    width: 180px;
    padding: 10px 0;
    .el-dropdown-menu__item {
        width: 100%;
        height: 34px;
        padding: 0;
        padding-left: 50px;
        line-height: 34px;
        color: #666;
        position: relative;
        display: flex;
        align-items: center;
        .selected-icon {
            position: absolute;
            left: 24px;
            top: 0;
            line-height: 34px;
            font-size: 14px;
        }
        .member-name {
            width: 56px;
            font-size: 14px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .feedback-status {
            width: 40px;
            height: 18px;
            display: inline-block;
            margin-right: 10px;
            line-height: 18px;
            text-align: center;
            color: #666;
            font-size: 12px;
            background-color: #f5f5f5;
            &.is-feedback {
                color: $primary;
            }
        }
        &.is-selected {
            color: $primary;
        }
    }
}
</style>
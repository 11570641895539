import { broadcast } from "./emitter.js";

export default {
    methods: {
        /**
         * @param {*} vuePopover popover的vue实例
         * @param {*} changeHeight popover内容中动态改变的高度
         * @param {*} ifOffset popover是否要展示在右下角
         */
        popoverShow(vuePopover, changeHeight = 0, ifOffset = false) {
            // let spanX = 0, spanWidth = 0;
            let spanClient = vuePopover.$el.getBoundingClientRect();
            let popperElm = vuePopover.popperElm;
            this.spanTop = spanClient.top;
            this.top = parseInt(popperElm.style.top);
            // this.left = parseInt(popperElm.style.left);
            // if(ifOffset) {
            //     spanX = spanClient.x;
            //     spanWidth = spanClient.width;
            //     if(document.body.clientWidth - spanX - spanWidth >= popperElm.clientWidth) {
            //         popperElm.style.left = (this.left + spanWidth) + 'px';
            //     } else {
            //         popperElm.style.left = (this.left - document.body.clientWidth + spanX) + 'px';
            //     }
            // }
            if(this.top > this.spanTop) {
                //页面剩余的高度小于当前悬浮卡片的高度的时候改变top值
                let leftMinHeight = document.body.clientHeight - this.top - changeHeight;
                if(popperElm.clientHeight > leftMinHeight ) {
                    popperElm.style.top = (this.top - popperElm.clientHeight - 35) + 'px';
                    this.top = parseInt(popperElm.style.top);
                }
            }
        },
        /**
         * 
         * @param {*} vuePopoverParent popover的父元素的vue实例
         * @param {*} popoverComponentName poppover的ComponentName
         */
        // updatePopoverPosition(vuePopoverParent, popoverComponentName) {
        //     vuePopoverParent.$el.addEventListener('scroll', () => {
        //         broadcast(popoverComponentName, 'updatePopper');
        //     });
        // }
    }
  };
  
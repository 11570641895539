import {trackingList as trackingListUrl, company as companyUrl} from '@src/js/config/api.json';

export default {
    /**
     * 
     * @param {
     *  teamId: 二级团队Id
     *  startDate: 搜索起始时间
     *  endDate: 搜索结束时间
     * } 
     */
    getMembers(params) {
        return _request({
            url: companyUrl.second_level_team_members,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi"
        })
    },
    /**
     * 
     * @param {
     *  snapshotIds: 快照id，仅团队周报时传，全部成员则需要传全部的成员对应的快照id列表
     *  userId: 仅个人周报时传
     *  startDate: 仅个人周报时传,
     *  endDate: 仅个人周报时传
     *  start: 索引
     *  take：页码
     * } 
     */
    getWeeklyReportData(params) {
        return _request({
            url: trackingListUrl.weekly_report_trackinglist,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi"
        })
    },
    /**
     * 
     * @param {
     *  trackingListSnapshotId: 快照id
     *  content: 反馈文本
     * } 
     */
    trackinglistFeedback(params) {
        return _request({
            url: trackingListUrl.feedback_trackinglist,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi"
        })
    }
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comment-table-container" },
    [
      _c(
        "el-table",
        {
          ref: "tlCommentTable",
          staticClass: "comment-table",
          attrs: {
            border: "",
            data: _vm.tableData,
            "empty-text": "-",
            "tooltip-effect": "light",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "成员姓名",
              prop: "userName",
              width: "120",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "职位",
              prop: "title",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "公司",
              prop: "company",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "城市",
              prop: "location",
              width: "88px",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: { label: "学历", width: "88px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("degreeFilter")(scope.row.degree)) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "学校",
              prop: "school",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "工作年限",
              prop: "yearOfExperience",
              width: "98px",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "候选人姓名",
              prop: "resumeRealName",
              width: "126px",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.resumeId && scope.row.isFirmResume
                      ? _c("span", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/#/candidateDetail/${scope.row.resumeId}`,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(scope.row.resumeRealName))]
                          ),
                        ])
                      : _c("span", [_vm._v(_vm._s(scope.row.resumeRealName))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "加入时间", width: "108px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("dateFilter")(scope.row.trackingDate)) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "推荐时间", width: "98px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("dateFilter")(scope.row.recommendDate)
                          ) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "寻访记录", width: "98px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        ref: "inquiryLogPopover",
                        attrs: {
                          placement: "bottom-start",
                          width: "310",
                          "popper-class": "inquiry-log-popper",
                          trigger: "hover",
                          "visible-arrow": false,
                          disabled: scope.row.inquiryLogsCount === 0,
                        },
                        on: {
                          show: function ($event) {
                            return _vm.showInquiryLog(scope.row)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [
                            _c("font-icon", {
                              staticClass: "table-body-icon-big icon-inquiry",
                              attrs: { href: "#icon-talent_inquiry" },
                            }),
                            _c("span", [
                              _vm._v(" " + _vm._s(scope.row.inquiryLogsCount)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: !scope.row.lastestInquiryLog,
                                expression: "!scope.row.lastestInquiryLog",
                              },
                            ],
                          },
                          [
                            scope.row.lastestInquiryLog
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "inquiry-log-userInfo" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: scope.row.lastestInquiryLog
                                            .creatorAvatar,
                                        },
                                      }),
                                      _c("span", { staticClass: "name" }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.lastestInquiryLog
                                              .creatorName
                                          )
                                        ),
                                      ]),
                                      _c("span", { staticClass: "time" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("timeFilter")(
                                              scope.row.lastestInquiryLog
                                                .created
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "inquiry-log-content" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.lastestInquiryLog.content
                                        )
                                      ),
                                    ]
                                  ),
                                  scope.row.lastestInquiryLog.audioRecordUrl
                                    ? _c("record-listen", {
                                        attrs: {
                                          controlBtnWidth: 10,
                                          progressWidth: 140,
                                          "audio-url":
                                            scope.row.lastestInquiryLog
                                              .audioRecordUrl,
                                          duration: _vm.getAudioDuration(
                                            scope.row.lastestInquiryLog
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.lastestInquiryLog
                                    .jobHoppingOccasion ||
                                  scope.row.lastestInquiryLog.employerInfo ||
                                  scope.row.lastestInquiryLog.expectSalary
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "inquiry-log-detail",
                                          style: scope.row.lastestInquiryLog
                                            .audioRecordUrl
                                            ? "margin-top: 5px;"
                                            : "",
                                        },
                                        [
                                          scope.row.lastestInquiryLog
                                            .jobHoppingOccasion
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "inquiry-log-detail-item",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    跳槽时机：" +
                                                      _vm._s(
                                                        scope.row
                                                          .lastestInquiryLog
                                                          .jobHoppingOccasion
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          scope.row.lastestInquiryLog
                                            .employerInfo
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "inquiry-log-detail-item",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    雇主画像：" +
                                                      _vm._s(
                                                        scope.row
                                                          .lastestInquiryLog
                                                          .employerInfo
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          scope.row.lastestInquiryLog
                                            .expectSalary
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "inquiry-log-detail-item",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    期望报酬：" +
                                                      _vm._s(
                                                        scope.row
                                                          .lastestInquiryLog
                                                          .expectSalary
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "加入渠道",
              "min-width": "70px",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "source-content" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(scope.row.addTrackingListSourceDetail) +
                          _vm._s(
                            scope.row.addTrackingListSourceDetail.indexOf(
                              "FloatingList"
                            ) === -1
                              ? "手动加入"
                              : "自动加入"
                          ) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("span", { staticClass: "empty-img" }),
            _c("span", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "work-table-footer" },
        [
          _c(
            "el-pagination",
            {
              staticClass: "el-pagination-workTable",
              attrs: {
                "current-page": _vm.page.current,
                "page-sizes": [15, 20, 50],
                "page-size": _vm.page.size,
                layout: "total, sizes, prev, pager, next, slot",
                total: _vm.page.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("前往"),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("页"),
                  ],
                  1
                ),
                _c("span", { on: { click: _vm.handlePagerJump } }, [
                  _vm._v("跳转"),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="tracking-list-comment">
        <div class="tracking-list-comment-content">
            <header class="tl-comment-title">
                <div class="table-title">{{name ? name + '的Tracking List' : 'Tracking List'}}</div>

                <comment-search
                    ref="commentSearch"
                    :teamId="teamId"
                    @search="handleSearch"
                ></comment-search>
            </header>
            <div v-loading="tableLoading">
                <comment-table
                    ref="commentTable"
                    :tableData="trackingListData"
                    @search="handleSearch"
                ></comment-table>
                <comment-container
                    ref="commentContainer"
                    v-if="isShowComment"
                    :isTeamLeader="isTeamLeader"
                    :comment="comment"
                    @comment-confirm="trackingListFeedback"
                    v-loading="feedbackLoading"
                ></comment-container>
            </div>
        </div>
    </div>
</template>

<script>
import CommentSearch from './layout/comment-search.vue';
import CommentTable from './layout/comment-table.vue';
import CommentContainer from './layout/comment-container.vue';
import TrackingListService from '#/js/service/trackingListService';
import moment from 'moment';
export default {
    components: {
        CommentSearch,
        CommentTable,
        CommentContainer
    },
    data() {
        return {
            name: "",
            isTeamLeader: false,
            isShowComment: false,
            teamId: "",
            params: {
                start: 0,
                take: 10
            },
            trackingListData: [],
            comment: {
                leaderName: "",
                createTime: "",
                content: "",
                trackingListSnapshotId: ""
            },
            tableLoading: false,
            feedbackLoading: false
        }
    },
    mounted() {
        let date = "";
        let name = this.$route.query.teamName ? this.$route.query.teamName : this.userInfo.nickname;
        this.name = decodeURI(this.formatUrlParam(name));
        this.teamId = this.$route.query.teamId || "";
        this.isTeamLeader = this.teamId ? true : false;
        this.$nextTick(() => {
            if(this.$route.query.startDate) {
                date = moment(decodeURI(decodeURIComponent(this.$route.query.startDate))).weekday(0).format("YYYY-MM-DD");
            } else {
                date = moment().weekday(0).subtract(7, 'days').format("YYYY-MM-DD");
            }
            this.$refs.commentSearch.handleSearchDate(date);
        })
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        }
    },
    methods: {
        formatUrlParam(str) {
            if(str.indexOf('%25') >-1) {
                return this.formatUrlParam(decodeURI(str));
            }else {
                return decodeURI(str);
            }
        },
        handleSearch(params, isSlient) {
            if(!isSlient && params) {
                params.start = 0;
                this.$refs.commentTable.page.current = 1;
            }
            Object.assign(this.params, params);
            if(!this.isTeamLeader || (this.params.snapshotIds.length === 1 || (this.$refs.commentSearch.commentSearchUser.trackingListSnapshotId))) {
                this.isShowComment = true;
            } else {
                this.isShowComment = false;
            }
            if(this.isTeamLeader) {
                if(this.params.snapshotIds.length >= 1) {
                    this.getData(isSlient);
                } else {
                    this.trackingListData = [];
                    this.$refs.commentTable.page.total = 0;
                }
            } else {
                this.params.userId = this.$store.state.user.userInfo.id;
                this.getData(isSlient);
            }
        },
        getData(isSlient = false) {
            this.tableLoading = true;
            TrackingListService.getWeeklyReportData(this.params)
            .then(res => {
                res.list.map( item => {
                    item.lastestInquiryLog = null;
                });
                this.tableLoading = false;
                this.trackingListData = res.list;
                this.$refs.commentTable.page.total = res.total;
                if(this.isShowComment && !isSlient) {
                    this.comment = {
                        trackingListSnapshotId: res.trackingListSnapshotId,
                        leaderName: res.feedbackContentLeaderName,
                        createTime: res.feedbackDate,
                        content: res.feedbackContent
                    };
                }
            }).catch(err => {
                this.tableLoading = false;
                console.log(err)
            })
        },
        trackingListFeedback(content) {
            this.feedbackLoading = true;
            TrackingListService.trackinglistFeedback({
                trackingListSnapshotId: this.comment.trackingListSnapshotId,
                content: content
            }).then(res => {
                this.feedbackLoading = false;
                this.comment = {
                    trackingListSnapshotId: res.trackingListSnapshotId,
                    leaderName: res.feedbackContentLeaderName,
                    createTime: res.feedbackDate,
                    content: res.feedbackContent
                }
                this.$refs.commentSearch.memberList.map(item, index => {
                    if(item.trackingListSnapshotId === res.trackingListSnapshotId) {
                        this.$set(this.$refs.commentSearch.memberList[index], 'isFeedback', true);
                    }
                })
            }).catch(err => {
                this.feedbackLoading = false;
            })
        }
    }
}
</script>

<style lang="scss">
.layout-transition {
    background-color: #f9f9f9;
}
</style>

<style lang="scss" scope>
.tracking-list-comment {
    padding: 20px;
    height: 100%;
    overflow-y: auto;

    .el-table thead th {
        background-color: #F0F4F7;
        color: #666;
        font-weight: bold;

        .cell {
            padding: 0 20px;
        }
    }

    .el-table th, .el-table td {
        padding: 8px 0;
    }

    &-content {
        padding-bottom: 38px;
        background-color: #fff;
        border-radius: 8px;
        min-height: calc(100vh - 90px);
    }

    .tl-comment-title {
        // text-align: center;
        // line-height: 30px;
        // font-size: 28px;
        // color: #666;
        // margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        line-height: 32px;
        padding: 30px 30px 0;
        margin-bottom: 28px;

        .table-title {
            font-size: 28px;
            color: #666;
            line-height: 32px;
        }
    }
}
</style>
